.container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    // background-color: aquamarine;
    box-sizing: content-box !important;
    @media screen and (max-width: $width-sm) {
      margin: auto;
      max-width: 54vw;
    }
    @media screen and (max-width: $width-md) {
      margin: auto;
      max-width: 96vw;
    }
    // @media screen and (max-width: $min-width-l) {
    //   margin: auto;
    //   max-width: 96vw;
    // }
    @media screen and (max-width: $width-lg) {
      margin: auto;
      max-width: 96vw;
    }
    @media screen and (max-width: $width-xl) {
      margin: auto;
      max-width: 96vw;
    }
  }
