@import "../../styles/theme_breakpoint.scss";

$height: 100vh;
$height-mobile: 90vh;
$nav-padding: 40px;

.section {
  // min-height: $height;
  padding: 0 10px;
  @media screen and (max-width: $width-sm) {
    // min-height: $height-mobile;
    // padding: 0 10px ;
  }
}

.hero {
  padding-top: $nav-padding;
  min-height: $height;
  @media screen and (max-width: $width-sm) {
    min-height: $height-mobile;
  }
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  // @media screen and (max-width: $width-sm) {

  //   position: absolute;
  //   top: 90%;
  //   -ms-transform: translateY(-50%);
  //   transform: translateY(-50%);

  // }
}

.xsection {
  min-height: 95vh;
  background-color: var(--bg);
  .divider .shape-fill {
    fill: var(--bg);
  }
}

.section-variant {
  min-height: 95vh;
  background-color: var(--bg-variant);
  .divider .shape-fill {
    fill: var(--bg-variant);
  }
}
.xcover {
  height: 100vh;
  .MuiGrid-grid-xs-12 {
    flex-basis: 0%;
  }
}
.xcover-image {
  display: flex;
  width: auto;
  height: 75vh;

  position: relative;
  // margin: auto;
  overflow: hidden;
  // z-index: -1;
  // width: 100px;
  justify-content: flex-end;
  align-items: flex-end;
  // style="display:flex;justify-content:flex-end;align-items:flex-end;">
  @media screen and (max-width: 599px) {
    // height: 5vh;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    justify-content: center !important;
    vertical-align: middle !important;
    display: flex !important;
    text-align: center !important;
  }
}

.center-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
