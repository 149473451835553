@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-Blinker: "Blinker", sans-serif !important;
$font-Rubik: "Rubik", sans-serif !important;

.MuiTypography-h1 {
  font-size: 3.8rem !important;
  font-family: $font-Rubik;
  font-weight: 600 !important;
  @media screen and (max-width: $width-sm) {
    font-size: 2.4rem !important;
  }
}

.MuiTypography-h2 {
  font-size: 3rem !important;
  font-family: $font-Rubik;
  font-weight: 600 !important;
  @media screen and (max-width: $width-sm) {
    font-size: 2.1rem !important;
  }
}

.MuiTypography-h3 {
  font-size: 2.5rem !important;
  font-family: $font-Blinker;
  font-weight: 500 !important;
  @media screen and (max-width: $width-sm) {
    font-weight: 400 !important;
    font-size: 170% !important;
  }
}
.MuiTypography-h4 {
  font-size: 2rem !important;
  font-family: $font-Blinker;
  font-weight: 500 !important;
  @media screen and (max-width: $width-sm) {
    font-weight: 400 !important;
    font-size: 170% !important;
  }
}
.MuiTypography-h5 {
  font-size: 1.5rem !important;
  font-family: $font-Blinker;
  font-weight: 500 !important;
  @media screen and (max-width: $width-sm) {
    font-weight: 400 !important;
    font-size: 170% !important;
  }
}
.MuiTypography-h6 {
  font-size: 1.3rem !important;
  font-family: $font-Blinker;
  font-weight: 500 !important;
  @media screen and (max-width: $width-sm) {
    font-weight: 400 !important;
    font-size: 170% !important;
  }
}
.MuiTypography-body1 {
  font-size: 1.2rem !important;
  font-family: $font-Rubik;
  @media screen and (max-width: $width-sm) {
  }
}
.MuiTypography-body2 {
  font-size: 1rem !important;
  font-family: $font-Blinker;
  font-weight: 500 !important;
  @media screen and (max-width: $width-sm) {
    font-weight: 400 !important;
  }
}

.blockquote {
  margin: 2rem 0 2rem 0 !important;
  padding: 0.1em 0 0.1em 0.6rem;
  border-left: 0.4rem solid var(--primary);
  display: block;
  @media screen and (max-width: $width-sm) {
    line-height: 1.7rem !important;
    font-weight: 500 !important;
    margin: 2rem 0 2rem 0 !important;
  }
}

.title {
  padding-top: 2rem;
}
.subtitle {
  padding-bottom: 25px;
}

.page-title {
  padding: 15px 0;
  font-size: 30px !important;
  color: var(--color-primary);
  text-transform: uppercase;
}
