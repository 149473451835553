/* Break points */
$width-xs: 0px;
$width-sm: 599px;
$mobile: 599px;
$width-md: 768px;
// $min-width-l: 1100px;
$width-lg: 992px;
// $min-width-xl: 1200px;
$width-xl: 1200px;

#content-desktop {
  display: inherit;
}
#content-mobile {
  display: none;
}

@media screen and (max-width: $width-sm) {
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: inherit;
  }
}
