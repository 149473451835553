@import "../../styles/theme_breakpoint.scss";

$circle-height: 380px;
$circle-width: 380px;
// mobile
$circle-height-mobile: 280px;
$circle-width-mobile: 280px;

.circle-inner {
  clip-path: path(
    "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"
  );
  position: relative;
  transform-origin: 50%;
  top: -150px;
  @media screen and (max-width: $width-sm) {
    height: 500px;
    width: 300px;
    top: -100px;
    clip-path: path("M 10 100 L 10 400 A 1 1 0 0 0 292 400 L 280 100");
  }
}
.circle {
  background-color: var(--background-color-2);
  border-radius: 50%;
  cursor: pointer;
  height: $circle-height;
  width: $circle-width;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 210px;

  @media screen and (max-width: $width-sm) {
    height: $circle-height-mobile;
    width: $circle-width-mobile;
    top: 260px;
  }
}
.img-pop {
  pointer-events: none;
  position: relative;
  transform: translateY(20px) scale(1.15);
  transform-origin: 50% bottom;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 22px;
  top: 164px;
  width: 340px;
  @media screen and (max-width: $width-sm) {
    transform: translateY(50px) scale(1.2);
    left: 32px;
    top: 170px;
    width: 240px;
  }
}
