// @import "../../theme/theme-colors.scss";

.MuiToolbar-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .MuiToolbar-regular {
    min-height: 10px !important;
    border-top: 5px solid transparent;
  }
  .MuiAppBar-colorPrimary {
    // color: inherit !important;
    background-color: transparent !important;
  }
  .app-bar-logo {
    img {
      height: 35px !important;
    }
  }
  
  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }
  
  @media screen and (max-width: 599px) {
    .btn-app-bar {
      font-weight: 700;
      border: none;
      text-transform: uppercase;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 1.8em;
      text-align: center;
      text-decoration: none;
      margin-right: 0.3em;
    }
  }
  
  .app-bar-logo img {
    height: 30px;
  }
  
  .btn-app-bar {
    // font-family: $font-Rubik;
    font-weight: 500;
    border: none;
    text-transform: uppercase;
    font-size: 1.2em !important;
    text-align: center;
    text-decoration: none;
    margin-right: 20px;
  
    position: relative;
    display: inline-block;
    color: var(--primary);
    // color: brown;
    &::before {
      position: absolute;
      content: attr(data-content);
      top: 0;
      left: 0;
      width: 0;
      // color: $color-on-background;
      color: var(--primary);
      text-decoration: underline;
      white-space: nowrap;
      overflow: hidden;
      transition: width 675ms ease;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .MuiList-root {
    // background-color: $color-background;
    background-color: var(--bg);
  }
  // .test{
  //   display: none !important;
  // }
  
  