@import "./theme_breakpoint.scss";
@import "./theme_typography.scss";
@import "./theme_container.scss";
@import "./theme_color.scss";
@import "./theme_nav.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section-variant {
  min-height: 95vh;
  background-color: var(--bg-variant);
  margin: auto;

  .divider .shape-fill {
    fill: var(--bg-variant);
  }
}

.icon-box {
  height: 60px;
  width: 60px;
  margin-right: 20px !important;
  display: inline-block;
}
.icon {
  font-size: 60px !important;
  color: var(--color-primary);
}
.icon-box:hover {
  transform: scale(1.2);
}
.shadow {
  text-align: center;
  padding: 0px 0px;
  transition: 0.5s all ease-in-out;
  position: relative;
  border-radius: 10px;
  background: var(--background-color-2);
  box-shadow: rgb(28, 30, 34) 10px 10px 19px 0px,
    rgb(38, 42, 46) -10px -10px 19px 0px;
  box-shadow: rgb(17, 18, 21) 10px 10px 19px 0px,
    rgb(19, 21, 23) -10px -10px 19px 0px;
  z-index: 1;
  margin: auto;
}

// padding
.padding-small {
  padding: 20px 0;
}
.padding-med {
  padding: 50px 0;
}
