:root.dark {
  --background-color-1: #212428;
  --background-color-2: #1f2125;

  // --color-primary: #ff014f;
  --color-primary: #29c7ac;
  // --color-primary: #ec625f;
  --color-secondary: #ffffff;
  --color-tertiary: #c4cfde;
}
:root.light {
  --background-color-1: #ecf0f3;
  --background-color-2: #e5eaee;

  // --color-primary: #ff014f;
  // --color-primary: #29c7ac;
  --color-primary: #ec625f;
  --color-secondary: #0d1013;
  --color-tertiary: #212930;
}

.color-primary {
  color: var(--color-primary);
}
.color-secondary {
  color: var(--color-secondary);
}
.color-tertiary {
  color: var(--color-tertiary);
}